import React, {useState} from "react";
import ListPage from "../../components/ListPage/ListPage";
import {useNavigate} from "react-router-dom";
import {Col, Row, Tooltip} from "antd";
import {useTranslation} from "react-i18next";


const MachineListPage = (props) => {
  const [reload, setReload] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const buildPageHeader = (data) =>{
    return null;
  }
  const buildCardHeader = (item)=>{
    return null;
  }

  const buildCardBody = (data)=>{
    const cardBodyFields = {
      'number': t('machine_carnumber_field'),
      'machine_type_text': t('machine_type_field'),
      'partner_name':  t('machine_owner_field'),
      'active_machine.start_date': t('machine_leasestart_field'),
      'active_machine.end_date': t('machine_leasend_field'),
    };
    let innerContents = [];
    if(cardBodyFields) {
      for (const key in cardBodyFields) {
        const nestedKeys = key.split(".");
        let value = data;
        for (const nestedKey of nestedKeys) {
          value = value[nestedKey];
          if (value === undefined) {
            break;
          }
        }
        if( key === 'machine_type_text'){
          innerContents.push(
              <Row
                  key={key}
                  style={{ width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
              >
                <Tooltip title={`${cardBodyFields[key]}: ${t(value)}`}>
                  <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {cardBodyFields[key]}: {t(value)}
                  </span>
                </Tooltip>
              </Row>)
        }
        else{
          innerContents.push(
              <Row
                  key={key}
                  style={{ width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
              >
                 <Tooltip title={`${cardBodyFields[key]}: ${value}`}>
                  <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {cardBodyFields[key]}: {value}
                  </span>
                 </Tooltip>
              </Row>)
        }
      }
    }
    return <Row style={{ display: 'flex', width: '100%' }} key={'cardContent'}>
      <Col span={12} style={{ paddingRight: '8px' }}>
        <Row style={{ flexDirection: 'column', justifyContent: 'start' }}>
          {innerContents}
        </Row>
      </Col>
    </Row>
  }

  const buildCardFooter=(data)=>{
    return null;
  }

  const buildFooterFields=(data)=>{
    return [<label style={{color: data.machine_status.color}}>{t(data.machine_status.status_text)}</label>]
  }

  const getCardBackgroundColor=(data)=>{
    if(data.machine_status.status === 1){
      return '#dfede2'
    }
    else if(data.machine_status?.status_text === 'notactive_label'){
      return '#FAA0A0'
    }
  }

  const config = {
    pageSize: props.pageSize || 10,
    url: props.url || `/api/v1/machines/`,
    hideEdit: props.hideEdit || true,
    hideAddButton: true,
    pageTitle: props.title || t('machine_listpage_title'),
    disableDelete: true,
    deleteTitle: t('machine_return_confirmation_text'),
    pageSubtitle: t('machine_listpage_subtitle'),
    searchPlaceHolder: t('listpage_search_text') +'...',
    cardTitleField: 'name',
    cardDetailButtonText: t('listpage_detail_button_text'),
    cardDeleteButtonText: 'Delete Selected',
    cardBodyFields: {
      'active_machine.machine_data.number': t('machine_carnumber_field'),
      'partner_name':  t('machine_owner_field'),
      'active_machine.start_date': t('machine_leasestart_field'),
      'active_machine.end_date': t('machine_leasend_field'),
    },
    cardFooterFields: [],
    addPermission: ['machine-create'],
    editPermission: ['machine-edit'],
    deletePermission: ['machine-return'],
    viewPermission: ['machine-detail'],
    orderingFields: [
      {id: '-name', name: t('machine_orderby_field1')},
      {id: 'name', name: t('machine_orderby_field2')},
      {id: '-number', name: t('machine_orderby_field3')},
      {id: 'number', name: t('machine_orderby_field4')},
      {id: '-created', name: t('machine_orderby_field5')},
      {id: 'created', name: t('machine_orderby_field6')},
    ],
    'buildPageHeader': buildPageHeader,
    'buildCardHeader': buildCardHeader,
    'buildCardBody': buildCardBody,
    'buildCardFooter': buildCardFooter,
    'buildFooterFields': buildFooterFields,
    'getCardBackgroundColor': getCardBackgroundColor,
  }

  const handleDeleteClick = (item)=>{
    return null;
  }

  const handleDetailClick=(item)=>{
    navigate('/machine/detail/', { replace: true, state: {machine: item} })
  }

  return (
      <>
        <ListPage
            config={config}
            // onAddClick={handleOpenModal}
            // onEditClick={(item)=>handleOpenModal(item, true)}
            onDeleteClick={(item)=>handleDeleteClick(item)}
            onDetailClick={(item)=>handleDetailClick(item)}
            reload={reload}
        />
      </>
  );
};

export default MachineListPage;
