import React, {useEffect, useState} from "react";
import ListPage from "../../components/ListPage/ListPage";
import http from "../../http";
import ManifestAddPage from "./ManifestAddPage";
import {useNavigate} from "react-router-dom";
import {Button, Col, message, Row, Tooltip, Typography} from "antd";
import {useTranslation} from "react-i18next";
import PermissionChecker from "../../PermissionChecker";
import EditPencilIcon from "../../components/CustomIcons/EditPencilIcon";
import {DeleteOutlined, CopyOutlined} from "@ant-design/icons";
import {CustomConfirmPopup} from "../../components/CustomConfirmPopup/CustomConfirmPopup";


const ManifestListPage = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [reload, setReload] = useState(false);
    const [edit, setEdit] = useState(false);
    const [duplicate, setDuplicate] = useState(false);
    const [item, setItem] = useState(false);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const buildPageHeader = (data) =>{
        return null;
    }
    const buildCardHeader = (item)=>{
        return <Row className={'cardHeader'} style={{alignItems:'center'}} key={'cardHeader'}>
            <Tooltip title={item? item[config.cardTitleField]: ''} placement={'bottomLeft'}>
                <Col key={'cardTitle'} span={16} style={{whiteSpace: 'nowrap', fontWeight: 'bold', fontSize:'20px', overflow:'hidden', textOverflow:'ellipsis'}}>{item? item[config.cardTitleField]: ''}</Col>
            </Tooltip>
            <Col key={'cardButtons'} span={8} style={{display:'flex', flexDirection:'row', justifyContent:'space-around', alignItems:'center'}}>
                <PermissionChecker requiredPermissions={['manifest-duplicate']}>
                    <Button icon={<CopyOutlined style={{color: '#377EF3'}} />} style={{border: 'none', background:'none'}} onClick={()=>handleOpenDuplicateModal(item)}/>
                </PermissionChecker>
                <PermissionChecker requiredPermissions={config.editPermission || []}>
                    <Button icon={<EditPencilIcon />} style={{border: 'none', background:'none'}} onClick={()=>handleOpenModal(item, true)}/>
                </PermissionChecker>
                <CustomConfirmPopup
                    title={t('manifest_delete_confirmation_text')}
                    onConfirm={()=>handleDeleteClick(item)}
                    icon={<DeleteOutlined style={{color: '#377EF3'}}/>}
                    okText={t('yes_text')}
                    cancelText={t('no_text')}
                    okType='default'
                >
                    <PermissionChecker requiredPermissions={config.deletePermission || []}><Typography.Link style={{color: config.disableDelete ? 'grey': '#377EF3'}}><DeleteOutlined style={{color: '#377EF3'}}/></Typography.Link></PermissionChecker>
                </CustomConfirmPopup>
            </Col>
        </Row>;
    }

    const buildCardBody = (data)=>{
        const cardBodyFields = {
            'construction_type_text': t('manifest_constructiontype_field'),
            'machine_type_text': t('manifest_machinetype_field'),
            'compaction_count': t('manifest_compactioncount_field'),
            'positioning_method_text': t('manifest_positioningmethod_field')
        };
        let innerContents = [];
        if(cardBodyFields) {
            for (const key in cardBodyFields) {
                const nestedKeys = key.split(".");
                let value = data;
                for (const nestedKey of nestedKeys) {
                    value = value[nestedKey];
                    if (value === undefined) {
                        break;
                    }
                }
                if(key === 'construction_type_text' || key === 'machine_type_text'){
                    innerContents.push(
                        <Row
                            key={key}
                            style={{ width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                        >
                            <Tooltip title={`${cardBodyFields[key]}: ${t(value)}`}>
                                <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                    {cardBodyFields[key]}: {t(value)}
                                </span>
                            </Tooltip>
                        </Row>
                    )
                }
                else{
                    innerContents.push(
                        <Row
                            key={key}
                            style={{ width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            <Tooltip title={`${cardBodyFields[key]}: ${value}`}>
                                <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                    {cardBodyFields[key]}: {value}
                                </span>
                            </Tooltip>
                        </Row>
                    )
                }
            }
        }
        const rightColumnContent = (
            <Row style={{ width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                <Tooltip title={`${t('last_edit_label')}: ${data.updated_by_text}`}>
                    <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {t('last_edit_label')}: {data.updated_by_text}
                    </span>
                </Tooltip>
            </Row>
        );
        return <Row style={{ display: 'flex', width: '100%' }} key={'cardContent'}>
            <Col span={12} style={{ paddingRight: '8px' }}>
                <Row style={{ flexDirection: 'column', justifyContent: 'start' }}>
                    {innerContents}
                </Row>
            </Col>
            <Col span={12} style={{ paddingLeft: '8px' }}>
                <Row style={{ flexDirection: 'column', justifyContent: 'start' }}>
                    {rightColumnContent}
                </Row>
            </Col>
        </Row>
    }

    const buildCardFooter=(data)=>{
        return null;
    }

    const buildFooterFields=(data)=>{
        return null;
    }

    const getCardBackgroundColor=(data)=>{
        if(data.active_manifest){
            return '#dfede2'
        }
    }

    const config = {
        pageSize: props.pageSize || 10,
        url: `/api/v1/projects/manifests/?project=${props.project.id}`,
        pageTitle: t('manifest_listpage_tile'),
        pageSubtitle: t('manifest_listpage_subtitle'),
        deleteTitle: t('manifest_delete_confirmation_text'),
        searchPlaceHolder: t('listpage_search_text') +'...',
        cardTitleField: 'name',
        cardDetailButtonText: t('listpage_detail_button_text'),
        cardDeleteButtonText: 'Delete Selected',
        cardBodyFields: {'construction_type_text': t('manifest_constructiontype_field'), 'machine_type_text': t('manifest_machinetype_field'), 'compaction_count': t('manifest_compactioncount_field')},
        cardFooterFields: [],
        addPermission: ['manifest-create'],
        editPermission: ['manifest-edit'],
        deletePermission: ['manifest-delete'],
        viewPermission: ['manifest-detail'],
        orderingFields: [
            {id: '-name', name: t('manifest_orderby_field1')},
            {id: 'name', name: t('manifest_orderby_field2')},
            {id: '-created', name: t('manifest_orderby_field3')},
            {id: 'created', name: t('manifest_orderby_field4')}
        ],
        'buildPageHeader': buildPageHeader,
        'buildCardHeader': buildCardHeader,
        'buildCardBody': buildCardBody,
        'buildCardFooter': buildCardFooter,
        'buildFooterFields': buildFooterFields,
        'getCardBackgroundColor': getCardBackgroundColor
    }
    const handleOpenDuplicateModal = (item) => {
        setDuplicate(true);
        setItem(item);
        setShowModal(true);
    };

    const handleOpenModal = (item, edit) => {
        if(edit){
            if(item.active_manifest){
                message.error(t('manifest_edit_validation_text'))
            }
            else {
                setDuplicate(false)
                setEdit(true);
                setItem(item);
                setShowModal(true);
            }
        }
        else {
            setDuplicate(false)
            setEdit(false);
            setItem(null);
            setShowModal(true);
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setDuplicate(false);
        setEdit(false);
        setItem(null);
    };
    const handleSuccessAdd = () =>{
        setEdit(false);
        setDuplicate(false);
        setItem(null);
        setReload(!reload);
        setShowModal(false);
    }

    const handleDeleteClick = (item)=>{
        if(item.active_manifest){
            message.error(t('running_manifest_delete_validation_text'))
        }
        else {
            http.delete(`/api/v1/projects/manifests/${item.id}/delete/`).then((resp) => {
                setReload(!reload);
            }).catch((err) => {
                if (err.response) {
                    message.error(err.response.data.message);
                }
            })
        }
    }
    const handleDetailClick=(item)=>{
        navigate('/manifest/detail/', { replace: true, state: {manifest: item, breadcrumbs:props.link} })
    }

    useEffect(()=>{
        setReload(!reload)
    }, [props.reload])

    return (
        <>
            <ListPage
                config={config}
                onAddClick={handleOpenModal}
                onEditClick={(item)=>handleOpenModal(item, true)}
                onDeleteClick={(item)=>handleDeleteClick(item)}
                onDetailClick={(item)=>handleDetailClick(item)}
                reload={reload}
            />
            <ManifestAddPage
                visible={showModal}
                onClose={handleCloseModal}
                onSuccessSubmit={handleSuccessAdd}
                edit={edit}
                duplicate={duplicate}
                editItem={item}
                project={props.project}
            />
        </>
    );
};

export default ManifestListPage;
